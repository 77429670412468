import {makeAutoObservable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {useNavigate} from "react-router-dom";
import {Bindings} from "data/constants/bindings";
import {type IContestStore} from "data/stores/contest/contest.store";
import {ContestStatusEnum, ModalType, QuestionStatusEnum} from "data/enums";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";
import {IContestsUserStat} from "data/types/user_stats";
import type {IUserStatsStore} from "data/stores/user_stats/user_stats.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {QuestionUtils} from "data/utils/question";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {AxiosError} from "axios";
import {IApiResponse} from "data/services/http";
import {UserStore, type IUserStore} from "data/stores/user/user.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";

interface IParams {
	contestId: number;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IContestItemController extends ViewController<IParams> {
	i18n: ILocalizationStore;
	goToQuestions: () => void;

	get contestName(): string;

	get competitionName(): string;

	get statusLabel(): string;

	get statusColor(): string;

	get contestStatus(): ContestStatusEnum;

	get correctPicks(): number | null;

	get yourPoints(): number | null;

	get yourRank(): number | null;

	get potentialPoints(): number | null;

	get selectedPicks(): number | null;

	get isEditMode(): boolean;

	get stats(): IContestsUserStat | undefined;

	get buttonColor(): "primary" | "secondary";

	get isLockContest(): boolean;
}

@injectable()
export class ContestItemController implements IContestItemController {
	private _navigate: IParams["navigate"] | null = null;
	private _contestID: number = 0;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.UserStatsStore) private _userStatsStore: IUserStatsStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	get contestName() {
		return this.contest?.name ?? "-";
	}

	get competitionName() {
		if (!this.contest) {
			return "-";
		}

		const comp = this._competitionsStore.getByID(this.contest.competitionId);
		const sport = comp?.sport ? `_${comp.sport}` : "";

		return this.i18n.t(`contest.item.competition${sport}`, comp?.name || "-");
	}

	get statusLabel() {
		if (this.contest) {
			return {
				[ContestStatusEnum.LIVE]: this.i18n.t("contest.item.status_live", "Live"),
				[ContestStatusEnum.COMPLETE]: this.i18n.t(
					"contest.item.status_complete",
					"Complete"
				),
				[ContestStatusEnum.OPEN]: this.i18n.t("contest.item.status_open", "Open"),
			}[this.contest.status];
		}
		return "-";
	}

	get stats() {
		return this._userStatsStore.getByID(this._contestID);
	}

	get statusColor() {
		if (this.contest) {
			const colors = {
				[ContestStatusEnum.LIVE]: "#9EDF69",
				[ContestStatusEnum.COMPLETE]: "#01439A",
				[ContestStatusEnum.OPEN]: "#FFCC00",
			};
			return colors[this.contest.status];
		}

		return "#335076";
	}

	get contestStatus() {
		return this.contest?.status ?? ContestStatusEnum.OPEN;
	}

	get correctPicks() {
		return this.stats?.correctAnswersCount || null;
	}

	get yourPoints() {
		return this.stats?.points || null;
	}

	get yourRank() {
		return this.stats?.rank || null;
	}

	get potentialPoints() {
		return this.stats?.potentialPoints || null;
	}

	get selectedPicks() {
		return this.stats?.answersCount || null;
	}

	get isEditMode() {
		const hasOpenQuestions = this.contest?.questions.find(QuestionUtils.IS_OPEN);

		return !!(this.selectedPicks && hasOpenQuestions);
	}

	private get contest() {
		return this._contestStore.getByID(this._contestID);
	}

	get buttonColor() {
		return this.contestStatus === ContestStatusEnum.COMPLETE ? "secondary" : "primary";
	}

	get isLockContest() {
		return (
			this.contest?.status === ContestStatusEnum.LIVE &&
			!this.contest?.questions.find(
				(question) =>
					question.status !== QuestionStatusEnum.COMPLETE &&
					question.status !== QuestionStatusEnum.LOCKED
			)
		);
	}

	init(param: IParams) {
		this._navigate = param.navigate;
		this._contestID = param.contestId;
	}

	goToQuestions = () => {
		if (this._userStore.isAuthorized) {
			if (this.contestStatus === ContestStatusEnum.COMPLETE) {
				this._navigate?.(`/contest/result/${this._contestID}`);
				return;
			}
			if (this.isEditMode) {
				createConnextraScriptTag(ConnextraType.EditPick, this._userStore.user);
			}
			this._navigate?.(`/contest/${this._contestID}`);
		} else {
			if (this._userStore.hasToken) {
				this._userStore.loginJWT().catch((e) => {
					const error = e as AxiosError<IApiResponse>;

					if (UserStore.REQUIRE_REGISTER_ERROR_CODE === error.response?.status) {
						this._modalStore.showModal(ModalType.REGISTER);
						return;
					}
					this._modalStore.showModal(ModalType.ERROR, {
						message: extractErrorMessage(error),
					});
				});
			} else {
				this._modalStore.showModal(ModalType.ERROR, {
					message: this.i18n.t(
						"error.no_session",
						"It is necessary to log in on the site"
					),
				});
			}
		}
	};
}
