import {IUser} from "data/stores/user/user.store";

export enum ConnextraType {
	Homepage = "f2p_predictor_homepage",
	RegStart = "f2p_predictor_regstart",
	RegConfirm = "f2p_predictor_regconfirm",
	LoggedIn = "f2p_predictor_loggedin",
	ContestPage = "f2p_predictor_contestpage",
	PickStart = "f2p_predictor_pickstart",
	PickConfirm = "f2p_predictor_pickconfirm",
	EditPick = "f2p_predictor_editpick",
	Leaderboard = "f2p_predictor_leaderboard",
	PrizesButton = "f2p_predictor_prizesbutton",
	HelpButton = "f2p_predictor_helpbutton",
	BetsLip = "f2p_predictor_betslip",
}

const CONNEXTRA_URL = `https://zz.connextra.com/dcs/tagController/tag/9f59ecdfc849/`;

export const createConnextraScriptTag = (type: ConnextraType, user?: IUser) => {
	console.log(type);
	const elementName = "script";
	const SCRIPT_ID = "connextra-tagging";
	document.getElementById(SCRIPT_ID)?.remove();
	const src = `${CONNEXTRA_URL}${type}`;

	const url = new URL(src);

	if (user?.id) {
		url.searchParams.append("AccountID", `${user.id}`);
	}

	const script = document.createElement(elementName);
	script.id = SCRIPT_ID;
	script.src = url.toString();
	script.async = true;
	script.defer = true;

	const firstJS = document.getElementsByTagName(elementName)[0];
	firstJS.parentNode!.insertBefore(script, firstJS);

	return script;
};
