import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {IQuestion, IAnswer} from "data/types/contests";
import {Bindings} from "data/constants/bindings";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IUser, IUserStore} from "data/stores/user/user.store";
import {QuestionStatusEnum, ContestStatusEnum} from "data/enums";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";
import {first, last} from "lodash";

interface IParams {
	contestId: number;
}

export enum ActiveResultTab {
	MyPicks,
	BetSlip,
	Leaderboards,
}

export interface IQuestionWithAnswer {
	question: IQuestion;
	answer?: IAnswer;
}

export interface IContestResultListController extends ViewController<IParams> {
	i18n: ILocalizationStore;
	setActiveTab: (tab: ActiveResultTab) => void;
	addQuestionsForBetslip: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
	isQuestionForBetslipChecked: (questionId: number) => boolean;
	sendDataToBetslip: () => void;

	get user(): IUser | undefined;

	get isLoggedIn(): boolean;

	get isComplete(): boolean;

	get activeTab(): ActiveResultTab;

	get hasUnlockedQuestions(): boolean;

	get questionsWithAnswers(): IQuestionWithAnswer[];

	get questionsForBetslip(): IQuestionWithAnswer[];
}

@injectable()
export class ContestResultListController implements IContestResultListController {
	@observable private _contestId = 0;
	@observable private _questionsForBetslip: IQuestionWithAnswer[] = [];

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore
	) {
		makeAutoObservable(this);
	}

	@observable private _activeTab = ActiveResultTab.BetSlip;

	get user(): IUser | undefined {
		return this._userStore.user;
	}

	get activeTab(): ActiveResultTab {
		return this._activeTab;
	}

	get isLoggedIn() {
		return this._userStore.isAuthorized;
	}

	get isComplete() {
		return this.contest?.status === ContestStatusEnum.COMPLETE;
	}

	get hasUnlockedQuestions() {
		return this.questionsWithAnswers.some((it) => {
			return it.question.status === QuestionStatusEnum.OPEN;
		});
	}

	get questionsWithAnswers() {
		if (!this.contest) {
			return [];
		}

		return this.contest.questions.map((question) => {
			const answer = this._answersStore.getByID(question.id);
			return {
				question,
				answer,
			};
		});
	}

	get questionsForBetslip() {
		return this._questionsForBetslip;
	}

	get sourceSelectionIDs() {
		const ids: string[] = [];
		this._questionsForBetslip.forEach((item) => {
			const selectedOption = item.question.options.find(
				(option) => option.id === item.answer?.value
			);

			if (selectedOption) {
				const splitSourceSelectionID = selectedOption.sourceSelectionID.split("/$/$/");

				if (splitSourceSelectionID.length === 1 || !splitSourceSelectionID.length) {
					ids.push(selectedOption.sourceSelectionID);
				} else if (splitSourceSelectionID.length) {
					// Get last array element from split array
					const lastSourceSelection = last(splitSourceSelectionID);
					const sourceID = first(lastSourceSelection?.split("/$/")) || "";
					ids.push(sourceID);
				}
			}
		});

		return ids;
	}

	private get contest() {
		return this._contestStore.getByID(this._contestId);
	}

	setActiveTab = (tab: ActiveResultTab) => {
		this._activeTab = tab;
	};

	addQuestionsForBetslip = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
		const questionId = parseInt(event.target.value, 0);
		const questionWithAnswer = this.questionsWithAnswers.find(
			({question}) => question.id === questionId
		);

		if (!questionWithAnswer) {
			return null;
		}

		if (checked) {
			this._questionsForBetslip = [...this._questionsForBetslip, questionWithAnswer];
		} else {
			this._questionsForBetslip = this._questionsForBetslip.filter(
				(data) => data.question.id !== questionId
			);
		}
	};

	isQuestionForBetslipChecked = (questionId: number) => {
		return Boolean(this._questionsForBetslip.find(({question}) => question.id === questionId));
	};

	sendDataToBetslip = () => {
		createConnextraScriptTag(ConnextraType.BetsLip, this.user);
		console.log(this.sourceSelectionIDs);
		window.parent.postMessage(
			{
				type: "bet_placed",
				selectionId: this.sourceSelectionIDs, // Add selected "sourceSelectionID" from questions
			},
			"*"
		);
	};

	init(param: IParams) {
		this._contestId = param.contestId;

		void this._contestStore.safeFetch();
		void this._answersStore
			.fetch({
				contestId: param.contestId,
			})
			.then(() => {
				this._activeTab = this.isComplete
					? ActiveResultTab.Leaderboards
					: ActiveResultTab.BetSlip;
				// Set all questions by default
				this._questionsForBetslip = this.questionsWithAnswers.filter(
					(data) => data.question?.status === QuestionStatusEnum.OPEN
				);
			});
	}
}
