import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IQuestion, IAnswer, IOption} from "data/types/contests";
import {MarketTypeEnum, QuestionStatusEnum, AnswerStatusEnum, QuestionTypeEnum} from "data/enums";
import {first} from "lodash";
import {createTranslationKey} from "data/utils";

interface IParams {
	question: IQuestion;
}

export interface IQuestionController extends ViewController<IParams> {
	i18n: ILocalizationStore;

	get options(): IOption[];

	get answer(): IAnswer | undefined;

	get answerOption(): IOption | undefined;

	get questionString(): string;

	get isTiebreaker(): boolean;

	get isLocked(): boolean;

	get isCorrect(): boolean;

	get isInCorrect(): boolean;

	get selectedOptionPoints(): number;

	addAnswer(optionId: number): void;

	addValueAnswer(value: string): void;

	isSelected(optionId: number): boolean;

	getAnswerStatus(optionId: number): AnswerStatusEnum | null;

	formatOption(option?: IOption): string;
}

@injectable()
export class QuestionController implements IQuestionController {
	@observable private _question: IQuestion | null = null;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore
	) {
		makeAutoObservable(this);
	}

	get questionString() {
		if (this._question?.type === QuestionTypeEnum.TIEBREAKER) {
			return this.i18n.t("question.type.tie_breaker", "TIE BREAKER");
		}

		const marketType = this._question?.marketType || MarketTypeEnum.EVENT_RESULT;

		return {
			[MarketTypeEnum.HANDICAP]: this.handicapQuestion,
			[MarketTypeEnum.EVENT_RESULT]: this.i18n.t(
				"question.type.event_result",
				"Who will win this match?"
			),
			[MarketTypeEnum.OVER_UNDER]: this.overUnderQuestion,
			[MarketTypeEnum.TOTAL_GOALS]: this.i18n.t(
				"question.type.total_goals",
				"How many total goals will there be?"
			),
		}[marketType];
	}

	get isLocked(): boolean {
		return this._question?.status !== QuestionStatusEnum.OPEN;
	}

	get isCorrect(): boolean {
		return !!this.answer && this.answer.value === this.answer.correctValue;
	}

	get isInCorrect(): boolean {
		return !!this.answer && this.answer.value !== this.answer.correctValue;
	}

	get isTiebreaker(): boolean {
		return this._question?.type === QuestionTypeEnum.TIEBREAKER;
	}

	get questionID() {
		return this._question?.id ?? 0;
	}

	get answer() {
		return this._answersStore.getByID(this.questionID);
	}

	get options(): IOption[] {
		if (!this._question) {
			return [];
		}
		if (first(this._question.options)?.name === "Under") {
			return this._question.options.slice().reverse();
		}

		return this._question.options;
	}

	get answerOption() {
		return this._question?.options.find((option) => option.id === this.answer?.value);
	}

	get selectedOptionPoints() {
		return this.answerOption?.points || 0;
	}

	private get overUnderQuestion() {
		const option = first(this._question?.options);

		if (option) {
			return this.i18n.t(
				"question.type.over_under",
				`Will there be over or under ${option.handicap} goals?`,
				{
					handicap: option.handicap,
				}
			);
		}

		return "";
	}

	private get handicapQuestion() {
		if (!this._question) {
			return "-";
		}

		const favoredTeam = this._question.options.find((option) => option.handicap < 0);
		const handicap =
			this._question.options.find((option) => option.handicap > 0)?.handicap || "-";

		if (favoredTeam) {
			return this.i18n.t(
				"question.type.handicap",
				`Will ${favoredTeam.name} win by more than ${handicap} goals?`,
				{
					handicap: handicap,
					favored_team: favoredTeam.name,
				}
			);
		}

		return "";
	}

	init(param: IParams) {
		this._question = param.question;
	}

	addAnswer(optionId: number): void {
		if (this.questionID && !this.isLocked) {
			this._answersStore.add(this.questionID, optionId);
		}
	}

	addValueAnswer(value: string): void {
		if (this.questionID) {
			const intValue = parseInt(value, 10);
			if (intValue < 0 || intValue > 99) {
				return;
			}
			if (value || intValue === 0) {
				this._answersStore.add(this.questionID, intValue);
			} else {
				this._answersStore.remove(this.questionID);
			}
		}
	}

	isSelected(optionId: number): boolean {
		return this._answersStore.has(this.questionID, optionId);
	}

	getAnswerStatus(optionId: number): AnswerStatusEnum | null {
		if (this.isSelected(optionId)) {
			if (this.answer?.correctValue) {
				if (this.answer?.value === this.answer.correctValue) {
					return AnswerStatusEnum.CORRECT;
				}

				if (this.answer.correctValue && !this.answer?.value) {
					return AnswerStatusEnum.MISSED;
				}

				return AnswerStatusEnum.IN_CORRECT;
			}

			if (this.isLocked) {
				return AnswerStatusEnum.LOCKED;
			}
		}

		return null;
	}

	getUpdatedOptionName(name: string) {
		return this.i18n.t(createTranslationKey(name, "question.option."), name);
	}

	formatOption(option?: IOption): string {
		if (!option) {
			return "";
		}
		if (this._question?.marketType === MarketTypeEnum.HANDICAP) {
			const prefix = option.handicap > 0 ? "+" : "";

			return `${this.getUpdatedOptionName(option.name)} ${prefix}${option.handicap}`;
		}
		if (this._question?.marketType === MarketTypeEnum.OVER_UNDER) {
			return this.i18n.t(
				`question.card.choice_${String(option.name).toLowerCase()}`,
				`${this.getUpdatedOptionName(option.name)} {{handicap}}`,
				{
					handicap: option.handicap,
				}
			);
		}

		return this.getUpdatedOptionName(option.name);
	}
}
