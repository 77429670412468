import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable, runInAction} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {IContest, IQuestion} from "data/types/contests";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import {size, compact} from "lodash";
import {RequestState, QuestionStatusEnum, ModalType} from "data/enums";
import {QuestionUtils} from "data/utils/question";
import {useNavigate} from "react-router-dom";
import {AxiosError} from "axios";
import type {IModalsStore} from "data/stores/modals/modals.store";
import {extractErrorMessage} from "data/utils";
import {IApiResponse} from "data/services/http";
import {FrameBridge} from "data/utils/frame_bridge";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";

interface IParams {
	contestID: number;
	questionId: number;
	navigate: ReturnType<typeof useNavigate>;
}

export interface IQuestionsController extends ViewController<IParams> {
	i18n: ILocalizationStore;

	toNext: () => void;
	toPrev: () => void;
	hasAnswer: (questionId: number) => boolean;

	get isLoading(): boolean;

	get isFirstQuestionLocked(): boolean;

	get questionIndex(): number;

	set questionIndex(index: number);

	get currentQuestion(): IQuestion | undefined;

	get questions(): IContest["questions"];

	get hasUnSavedAnswers(): boolean;

	get isAllQuestionsAnswered(): boolean;

	save(): void;
}

@injectable()
export class QuestionsController implements IQuestionsController {
	@observable private _contestID: number = 0;
	@observable private _requestState: RequestState = RequestState.IDLE;
	@observable private _navigate: IParams["navigate"] | null = null;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.AnswersStore) private _answersStore: IAnswersStore,
		@inject(Bindings.ModalsStore) private _modalStore: IModalsStore
	) {
		makeAutoObservable(this);
	}

	@observable private _questionIndex: number = 0;

	get questionIndex() {
		return this._questionIndex;
	}

	set questionIndex(index: number) {
		if (index < 0) {
			return;
		}

		const questionSize = size(this.questions);

		if (index > questionSize - 1) {
			return;
		}

		this._questionIndex = index;
	}

	get isLoading() {
		return this._requestState === RequestState.PENDING;
	}

	get isFirstQuestionLocked() {
		return QuestionUtils.IS_FIRST_LOCKED(this.contest);
	}

	get contest() {
		return this._contestStore.getByID(this._contestID);
	}

	get questions() {
		const questions = this.contest?.questions;

		if (!questions) {
			return [];
		}

		const tiebreakerQuestionID = questions.find(QuestionUtils.IS_TIE_BREAKER)?.id;

		if (this.isFirstQuestionLocked && tiebreakerQuestionID) {
			const hasTiebreakerAnswer = this._answersStore.getByID(tiebreakerQuestionID);

			if (!hasTiebreakerAnswer) {
				return questions.filter((question) => question.id !== tiebreakerQuestionID);
			}
		}

		return this.contest?.questions || [];
	}

	get currentQuestion() {
		return this.questions[this._questionIndex];
	}

	get hasUnSavedAnswers() {
		return this._answersStore.hasChanges;
	}

	get isAllQuestionsAnswered() {
		const openQuestions = this.questions.filter(QuestionUtils.IS_OPEN);

		return !openQuestions.some((question) => !this._answersStore.getByID(question.id));
	}

	dispose(): void {
		this._answersStore.offAnswer(this.toNext);
	}

	async save() {
		this._requestState = RequestState.PENDING;
		try {
			await this._answersStore.save({
				contestId: this._contestID,
			});

			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});

			createConnextraScriptTag(ConnextraType.PickConfirm, this._userStore.user);

			this.fireBetSlipEvent();
			setTimeout(() => {
				this.toResults();
			}, 500);
		} catch (e) {
			this._modalStore.showModal(ModalType.ERROR, {
				message: extractErrorMessage(e as AxiosError<IApiResponse>),
			});
			runInAction(() => {
				this._requestState = RequestState.ERROR;
			});
		}
	}

	toResults = () => {
		this._navigate?.(`/contest/result/${this._contestID}`);
	};

	toNext = () => {
		this.questionIndex = this.questionIndex + 1;
	};

	toPrev = () => {
		this.questionIndex = this.questionIndex - 1;
	};

	hasAnswer = (questionId: number) => {
		return !!this._answersStore.getByID(questionId);
	};

	init(params: IParams): void {
		this._contestID = params.contestID;
		this._navigate = params.navigate;
		createConnextraScriptTag(ConnextraType.PickStart, this._userStore.user);

		this.setQuestionById(params.questionId);

		this._requestState = RequestState.PENDING;

		void this._answersStore
			.fetch({
				contestId: params.contestID,
			})
			.then(() => {
				runInAction(() => {
					this._requestState = RequestState.SUCCESS;
				});
			})
			.catch(() => {
				runInAction(() => {
					this._requestState = RequestState.ERROR;
				});
			});

		this._answersStore.onAnswer(this.toNext);
	}

	setQuestionById(questionId: number) {
		if (questionId) {
			this.questionIndex = this.questions.findIndex((question) => question.id === questionId);
			return;
		}

		const openQuestion = this.questions.findIndex(
			(it) => it.status === QuestionStatusEnum.OPEN
		);

		if (openQuestion > -1) {
			this.questionIndex = openQuestion;
		} else {
			this.questionIndex = 0;
		}
	}

	fireBetSlipEvent() {
		FrameBridge.setCallBacks({
			error: (e: unknown) => {
				this._modalStore.showModal(ModalType.ERROR, {
					message: JSON.stringify(e),
				});
			},
			selections_added: () => {
				console.log("Confirm of selections added");
			},
			bet_placed: () => {
				console.log("Confirm bet_placed");
			},
		});
		FrameBridge.sendMessage(
			compact(
				this.contest?.questions
					.filter((it) => it.status === QuestionStatusEnum.OPEN)
					.map((question) => {
						const answer = this._answersStore.getByID(question.id);
						const answerOption = question.options.find((it) => it.id === answer?.value);

						return (
							answerOption && {
								event_bet_selection_id: answerOption.sourceSelectionID,
								bt_cat_id: answerOption.btCatId,
							}
						);
					})
			)
		);
	}
}
