/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-duplicate-string */

/*
 * Disabled the following rules:
 * @typescript-eslint/naming-convention
 * @typescript-eslint/no-empty-interface
 * @typescript-eslint/no-unused-vars
 * sonarjs/no-duplicate-string
 * as MUI theme override required override interfaces.
 * Also, it has no sense to move out css variables into another variable
 *
 * More details on style overrides here https://mui.com/material-ui/customization/theme-components/#default-props
 */
import {css, ThemeOptions} from "@mui/material/styles";

declare module "@mui/material/Button" {
	// allows to rewrite theme options

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonPropsVariantOverrides {
		linkMenu: true;
	}

	// eslint-disable-next-line @typescript-eslint/naming-convention
	interface ButtonClasses {
		linkMenu: string;
	}
}

export const buttonsTheme: ThemeOptions = {
	components: {
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					margin: 2,
					textTransform: "none",
				},
				linkMenu: () => css`
					text-transform: none;
					background: #1b2332;
					color: #8e8e93;
					border-radius: 0;
					font-size: 16px;
					font-style: normal;
					font-weight: 700;
					line-height: 150%;
					letter-spacing: 0.16px;
					min-width: 120px;
					margin-right: 5px;
					&.active,
					&:hover {
						background: #333e55;
						color: #ffffff;
					}

					@media (max-width: 480px) {
						padding: 0 5px;
					}
				`,
				outlinedPrimary: css`
					height: 48px;
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: 150%;
					letter-spacing: 0.08px;
					text-transform: uppercase;
					border-radius: 8px;
					border: 1px solid #fc0;

					&:disabled {
						border-color: #2d4b72;
						color: #2d4b72;

						svg {
							color: #e1efff;
						}
					}
				`,
				contained: css`
					height: 48px;
					font-weight: 600;
					line-height: 150%;
					letter-spacing: 0.08px;
					text-transform: uppercase;

					&:disabled {
						opacity: 0.3;
					}
				`,
				containedPrimary: (props) => css`
					background: #ffcc00;
					color: ${props.theme.palette.colors.fifth};

					&:hover {
						background: #feea00;
					}

					&:disabled {
						background: #008b3a;
						color: ${props.theme.palette.colors.fifth};
						opacity: 0.3;
					}
				`,
				containedSecondary: (props) => css`
					background: #ecbe1a;
					background: ${props.theme.palette.backgrounds.third};
					color: ${props.theme.palette.colors.second};
					font-weight: 900;
					font-feature-settings:
						"clig" off,
						"liga" off;
					&:hover {
						background: #ffd953;
					}

					&:disabled {
						background: #ffd953;
						color: ${props.theme.palette.colors.second};
						opacity: 0.3;
					}
				`,

				containedSizeSmall: css`
					width: auto;
					padding: 8px 16px;
					height: auto;
					line-height: 1;
				`,
				containedSizeLarge: css`
					width: 100%;
				`,
			},
		},
		MuiIconButton: {
			styleOverrides: {
				colorInherit: {
					background: "transparent",
				},
			},
		},
	},
};
