import {makeAutoObservable, observable} from "mobx";
import {ViewController} from "data/types/structure";
import {injectable, inject} from "inversify";
import {Bindings} from "data/constants/bindings";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {RequestState} from "data/enums";
import {IContest} from "data/types/contests";
import type {IContestStore} from "data/stores/contest/contest.store";
import {ILeaderboardItem} from "data/types/leaderboard";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";
import type {IUserStore} from "data/stores/user/user.store";

interface IParams {
	contestId: number;
}

export enum TableStats {
	PICKS = "picks",
	TIE_BREAKER = "tie_breaker",
	TOTAL_POINTS = "total_points",
}

interface IParams {
	contestId: number;
}

/**
 * Used for
 * views/components/leaderboard/contest_leaderboard/contest_leaderboard.controller.ts
 * views/components/leaderboard/overall_leaderboard/overall_leaderboard.controller.ts
 */
export interface ICommonLeaderboardController extends ViewController<IParams> {
	i18n: ILocalizationStore;
	onLoadMore: () => Promise<void>;

	get isLoading(): boolean;

	get userId(): number;

	get hasMore(): boolean;

	get list(): ILeaderboardItem[];
}

export interface ILeaderboardController extends ViewController<IParams> {
	setStats: (value: TableStats) => void;
	setContestId: (contestId: number) => void;

	get selectedContestId(): number;

	get selectedStat(): TableStats;

	get completedOrLiveContests(): IContest[];
}

@injectable()
export class LeaderboardController implements ILeaderboardController {
	@observable private _contestId: number = 0;
	@observable private _requestState: RequestState = RequestState.IDLE;
	private _currentPage = 1;

	constructor(
		@inject(Bindings.ContestStore) public _contestStore: IContestStore,
		@inject(Bindings.UserStore) private _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	@observable private _selectedStat: TableStats = TableStats.TOTAL_POINTS;

	get selectedStat() {
		return this._selectedStat;
	}

	get selectedContestId(): number {
		return this._contestId;
	}

	get completedOrLiveContests() {
		return this._contestStore.completedOrLiveContests;
	}

	setStats = (value: TableStats) => {
		this._selectedStat = value;
	};

	setContestId = (contestId: number) => {
		this._contestId = contestId;
		this._currentPage = 1;
	};

	init(param: IParams) {
		this._contestId = param.contestId;
		createConnextraScriptTag(ConnextraType.Leaderboard, this._userStore.user);
	}
}
