import {inject, injectable} from "inversify";
import type {IUser} from "data/stores/user/user.store";
import type {IApiResponse, IHttpClientService} from "data/services/http";
import type {AxiosResponse} from "axios";
import {Bindings} from "data/constants/bindings";

export interface ILoginResponse {
	user: IUser;
	id: string;
}
export interface ILoginPayload {
	email: string;
}

export interface ILoginJWTPayload {
	token: string;
}

export interface IRegistrationJWTPayload {
	token: string;
	terms: boolean;
}

type TLoginResponse = IApiResponse<{session: ILoginResponse}>;

export interface IAuthApiProvider {
	backdoor: (params: ILoginPayload) => Promise<AxiosResponse<TLoginResponse>>;
	loginJWT: (params: ILoginJWTPayload) => Promise<AxiosResponse<TLoginResponse>>;
	registerJWT: (params: IRegistrationJWTPayload) => Promise<AxiosResponse<TLoginResponse>>;
	logout: () => Promise<AxiosResponse>;
	getUser: () => Promise<AxiosResponse<IApiResponse<{user: IUser}>>>;
}

@injectable()
export class AuthApiProvider implements IAuthApiProvider {
	constructor(@inject(Bindings.ApiHTTPClient) private _http: IHttpClientService) {}

	backdoor = (params: ILoginPayload) => this._http.post<TLoginResponse>(`auth/backdoor`, params);

	loginJWT = (params: ILoginJWTPayload) =>
		this._http.post<TLoginResponse>(`auth/jwt/login`, params);

	registerJWT = (params: IRegistrationJWTPayload) =>
		this._http.post<TLoginResponse>(`user/jwt/register`, params);

	logout = () => this._http.post(`auth/logout`);

	getUser = () => this._http.get<IApiResponse<{user: IUser}>>(`user`);
}
