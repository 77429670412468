import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";
import {INavigationRoute} from "data/types/navigation";
import type {IUserStore} from "data/stores/user/user.store";

export interface IHeaderController extends ViewController {
	toggleMenu: () => void;

	get isLoggedIn(): boolean;

	get routes(): INavigationRoute[];

	get menuState(): boolean;

	set menuState(value: boolean);
}

@injectable()
export class HeaderController implements IHeaderController {
	@observable private _isOpenMobileMenu = false;

	constructor(
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.UserStore) public _userStore: IUserStore
	) {
		makeAutoObservable(this);
	}

	get isLoggedIn() {
		return this._userStore.isAuthorized;
	}

	get routes(): INavigationRoute[] {
		return [
			{
				label: this.i18n.t("navigation.home", "Home"),
				path: "/dashboard",
			},
			{
				label: this.i18n.t("navigation.leaderboard", "Leaderboard"),
				path: "/leaderboard",
			},
			{
				label: this.i18n.t("navigation.prizes", "Prizes"),
				path: "/prizes",
			},
			{
				label: this.i18n.t("navigation.help", "Help"),
				path: "/help",
			},
		];
	}

	get menuState() {
		return this._isOpenMobileMenu;
	}

	set menuState(value: boolean) {
		this._isOpenMobileMenu = value;
	}

	toggleMenu = () => {
		this.menuState = !this.menuState;
	};

	dispose(): void {
		return;
	}

	init() {
		return;
	}
}
