interface ISelection {
	event_bet_selection_id: string;
	bt_cat_id: number | null;
}

export class FrameBridge {
	private static TOKEN: string = new URLSearchParams(window.location.search).get("token") ?? "";
	static ERROR: string | null = null;
	static client: IntegrationHandler | null = null;

	static CLEAR() {
		FrameBridge.TOKEN = "";
		FrameBridge.ERROR = "";
	}

	public static async listenToken(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			console.log("listenToken");
			console.log(FrameBridge);

			if (FrameBridge.TOKEN) {
				resolve(FrameBridge.TOKEN);
				return;
			}
			window.addEventListener(
				"message",
				(event: MessageEvent<{type: string; token: string}>) => {
					console.log(event);

					if (event.data.type === "load") {
						if (!event.data?.token) {
							FrameBridge.ERROR = "Smth went wrong...";
							reject(FrameBridge.ERROR);
							return;
						}

						resolve(event.data.token);
					}
				}
			);

			window.parent.postMessage({type: "gs_ready"}, "*");
		});
	}

	static setCallBacks(methods: Partial<IntegrationHandler["config"]["methods"]>) {
		const client = FrameBridge.client;
		if (client) {
			client.config.methods = {
				...client.config.methods,
				...methods,
			};
			client.setBehaviour();
		}
	}

	static sendMessage(data?: ISelection[]) {
		if (!data) {
			return;
		}
		console.log(`sendSelections with payload: ${JSON.stringify(data)}`);
		FrameBridge.client?.sendSelections(JSON.stringify(data));
	}
}
