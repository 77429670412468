import {ViewController} from "data/types/structure";
import {inject, injectable} from "inversify";
import {type IUserStore} from "data/stores/user/user.store";
import {Bindings} from "data/constants/bindings";
import {makeAutoObservable, observable} from "mobx";
import type {ILocalizationStore} from "data/stores/localization/localization.store";
import type {IContestStore} from "data/stores/contest/contest.store";
import {RequestState, ContestStatusEnum} from "data/enums";
import {IContest} from "data/types/contests";
import {useNavigate} from "react-router-dom";
import type {ICompetitionsStore} from "data/stores/competitions/competitions.store";
import type {IAnswersStore} from "data/stores/answers/answers.store";
import type {IUserStatsStore} from "data/stores/user_stats/user_stats.store";
import {ConnextraType, createConnextraScriptTag} from "data/utils/connextra";

interface IParams {
	navigate: ReturnType<typeof useNavigate>;
}

export interface IContestListController extends ViewController<IParams> {
	i18n: ILocalizationStore;

	get isLoading(): boolean;

	get isLoggedIn(): boolean;

	get contests(): IContest[];

	get actualContestIndex(): number;

	goToQuestions(contestId: number): void;
}

@injectable()
export class ContestListController implements IContestListController {
	@observable private _contestState: RequestState = RequestState.IDLE;
	private _navigate: IParams["navigate"] | null = null;

	constructor(
		@inject(Bindings.UserStore) private _userStore: IUserStore,
		@inject(Bindings.LocalizationStore) public i18n: ILocalizationStore,
		@inject(Bindings.ContestStore) private _contestStore: IContestStore,
		@inject(Bindings.AnswersStore) private _answerStore: IAnswersStore,
		@inject(Bindings.CompetitionsStore) private _competitionsStore: ICompetitionsStore,
		@inject(Bindings.UserStatsStore) private _userStatsStore: IUserStatsStore
	) {
		makeAutoObservable(this);
	}

	get isLoading() {
		return this._contestState === RequestState.PENDING;
	}

	get isLoggedIn() {
		return this._userStore.isAuthorized;
	}

	get contests() {
		return this._contestStore.list;
	}

	get actualContestIndex() {
		const actualIndex = this.contests.findIndex(
			(it) => it.status !== ContestStatusEnum.COMPLETE
		);
		return actualIndex === -1 ? 0 : actualIndex;
	}

	goToQuestions(contestId: number) {
		if (this.isLoggedIn) {
			this._navigate?.(`/contest/${contestId}`);
		} else {
			alert("Logging..");
		}
	}

	dispose(): void {
		this._contestState = RequestState.IDLE;
		this._answerStore.clear();
	}

	async init(params: IParams): Promise<void> {
		this._navigate = params.navigate;
		createConnextraScriptTag(ConnextraType.ContestPage);
		if (this.isLoggedIn) {
			await this.fetchData();
		}
	}

	private async fetchData() {
		this._contestState = RequestState.PENDING;
		try {
			await Promise.all([this._userStatsStore.fetch()]);
			this._contestState = RequestState.SUCCESS;
		} catch (e) {
			this._contestState = RequestState.ERROR;
		}
	}
}
