import axios, {
	AxiosInstance,
	AxiosInterceptorManager,
	AxiosRequestConfig,
	AxiosResponse,
} from "axios";

type IRequestConfig = [url: string, params?: object, requestConfig?: AxiosRequestConfig];

export interface IApiResponse<TResponse = unknown, TError = {message: string}> {
	success: TResponse;
	errors: TError[];
}

export interface IHttpClientService {
	interceptors: {
		readonly request: AxiosInterceptorManager<AxiosRequestConfig>;
		readonly response: AxiosInterceptorManager<AxiosResponse>;
	};

	/**
	 * Used to append locale/lang parameter in between BASE_URL and API path.
	 */
	setLocale(locale: string): void;
	request<T = unknown>(requestConfig: AxiosRequestConfig): Promise<AxiosResponse<T>>;
	get<T = unknown>(...args: IRequestConfig): Promise<AxiosResponse<T>>;
	post<T = unknown>(...args: IRequestConfig): Promise<AxiosResponse<T>>;
	put<T = unknown>(...args: IRequestConfig): Promise<AxiosResponse<T>>;
	delete<T = unknown>(...args: IRequestConfig): Promise<AxiosResponse<T>>;
	patch<T = unknown>(...args: IRequestConfig): Promise<AxiosResponse<T>>;
}

/**
 * HTTP Client created as wrapper of axios library.
 * Can be used to perform post/get/put/delete http methods via common interface.
 *
 * ### Example of usage
 * ```js
 * import axios from 'axios';
 *
 * const APIClient = new HttpClientService({
 * 	baseURL: process.env.REACT_APP_API_URL || '',
 * 	withCredentials: true,
 * });
 *
 * APIClient.post('login', credentials);
 * APIClient.get('user/show_my');
 *
 * const JSONClient = new Http_clientService({
 * 	baseURL: process.env.REACT_APP_JSON_URL || '',
 * });
 *
 * JSONClient.get('players.json');
 * ```
 */
export class HttpClientService implements IHttpClientService {
	private readonly config: AxiosRequestConfig;
	private readonly HttpClient: AxiosInstance;
	private _locale?: string;
	private _sessionId?: string;

	public get interceptors() {
		return this.HttpClient.interceptors;
	}

	constructor(config: AxiosRequestConfig) {
		this.config = config;
		this.HttpClient = axios.create(this.config);
	}

	setLocale(locale: string) {
		this._locale = locale;
	}

	private constructURL(url: string) {
		return this._locale ? `${this._locale}/${url}` : url;
	}

	/**
	 * Performs pure request without calling unknown hooks.
	 */
	public request<T = unknown>(requestConfig: AxiosRequestConfig) {
		return this.HttpClient.request<T>(requestConfig);
	}

	/**
	 * Performs `get` http method with call of all existing hooks.
	 */
	public get<T = unknown>(url: string, params?: object, requestConfig?: AxiosRequestConfig) {
		return this.request<T>({params, url: this.constructURL(url), ...requestConfig});
	}

	/**
	 * Performs `delete` http method with call of all existing hooks.
	 */
	public delete<T = unknown>(url: string, params?: object, requestConfig?: AxiosRequestConfig) {
		return this.request<T>({
			data: params,
			method: "delete",
			url: this.constructURL(url),
			...requestConfig,
		});
	}

	/**
	 * Performs `post` http method with call of all existing hooks.
	 */
	public post<T = unknown>(url: string, params?: object, requestConfig?: AxiosRequestConfig) {
		return this.request<T>({
			data: params,
			method: "post",
			url: this.constructURL(url),
			...requestConfig,
		});
	}

	/**
	 * Performs `put` http method with call of all existing hooks.
	 */
	public put<T = unknown>(url: string, params?: object, requestConfig?: AxiosRequestConfig) {
		return this.request<T>({
			data: params,
			method: "put",
			url: this.constructURL(url),
			...requestConfig,
		});
	}

	/**
	 * Performs `put` http method with call of all existing hooks.
	 */
	public patch<T = unknown>(url: string, params?: object, requestConfig?: AxiosRequestConfig) {
		return this.request<T>({
			data: params,
			method: "patch",
			url: this.constructURL(url),
			...requestConfig,
		});
	}
}
