import React, {Fragment} from "react";
import {ModalError} from "views/components/modals/modal_error/modal_error.component";

export const Modals: React.FC = () => {
	return (
		<Fragment>
			<ModalError />
		</Fragment>
	);
};
